import { Button, styled } from '@mui/material';

export const GenerateButton = styled(Button)<{ inactive?: number }>(({ inactive }) => ({
    width: '300px',
    transition: 'all 0.5s',

    ...(inactive && {
        color: 'rgba(255, 255, 255, 0.3)',
        boxShadow: 'none',
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
        '&:hover': {
            cursor: 'default',
            boxShadow: 'none',
            backgroundColor: 'rgba(255, 255, 255, 0.12)',
        },
        '&:active': {
            boxShadow: 'none',
        },
    }),
}));
